import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./Portfolio.css";
import Portfolio from "./Portfolio.js";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Portfolio />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
