import { Link } from "react-scroll";
import {
	MapPin,
	DownloadSimple,
	GithubLogo,
	LinkedinLogo,
	ArrowDownRight,
} from "@phosphor-icons/react";

import "./Portfolio.css";
import myCV from "./assets/Jack-Searle_CV.pdf";
import Experience from "./Experience.js";
import Projects from "./Projects.js";
import Contact from "./Contact.js";

function Portfolio() {
	return (
		<div className="Portfolio">
			<div className="content">
				<div className="profile">
					<div className="intro-panel">
						<div className="intro">
							<p className="intro-greeting">
								<span>👋</span> Hello world. I'm
							</p>
							<h1 className="full-name">Jack Searle</h1>
						</div>
						<div className="shortcuts">
							<Link
								to="about-section"
								className="shortcut-link"
								offset={-100}
								smooth={true}
								duration={300}
							>
								<ArrowDownRight size={26} className="shortcut-arrow" />
								<span>About</span>
							</Link>
							<Link
								to="experience-section"
								className="shortcut-link"
								offset={-100}
								smooth={true}
								duration={300}
							>
								<ArrowDownRight size={26} className="shortcut-arrow" />
								<span>Experience</span>
							</Link>
							<Link
								to="project-section"
								className="shortcut-link"
								offset={-100}
								smooth={true}
								duration={300}
							>
								<ArrowDownRight size={26} className="shortcut-arrow" />
								<span>Projects</span>
							</Link>
							<Link
								to="contact-section"
								className="shortcut-link"
								offset={-100}
								smooth={true}
								duration={300}
							>
								<ArrowDownRight size={26} className="shortcut-arrow" />
								<span>Contact</span>
							</Link>
						</div>
						<p className="location">
							<MapPin size={32} className="location-pin" /> Christchurch, NZ
						</p>
						<div className="ext-links">
							<a href={myCV} download="Jack-Searle_CV" className="CV-download">
								<DownloadSimple
									size={32}
									color="#F5F5F5"
									className="CV-download-icon"
								/>
								<p> Download CV</p>
							</a>
							<a
								href="https://www.github.com/jcksrl"
								target="_blank"
								rel="noreferrer"
								className="social-link"
							>
								<GithubLogo size={40} className="social-icon" />
							</a>
							<a
								href="https://www.linkedin.com/in/jack-searle-587a8b290/"
								target="_blank"
								rel="noreferrer"
								className="social-link"
							>
								<LinkedinLogo size={40} className="social-icon" />
							</a>
							{/* <a href='https://www.twitter.com' target='_blank' rel="noreferrer" className='social-link'><TwitterLogo size={32} /></a> */}
						</div>
					</div>
				</div>
				<div className="resume">
					<div className="section" id="about-section">
						<h1 className="section-header">About Me</h1>
						<p>
							I design and develop web, software and games.
							<br />
							<br />
							For as long as I can remember I've always had an interest in
							technology, which lead me to pick up coding as a hobby during
							highschool. Since then, I've studied and practiced my craft in
							order to make a career from which I love.
							<br />
							<br />
							When I'm away-from-keyboard I'm likely listening to music,
							watching a film, enjoying time with friends and family, or playing
							video games.
						</p>
					</div>
					<Experience />
					<Projects />
					<Contact />
				</div>
			</div>
		</div>
	);
}

export default Portfolio;
