export default function Projects() {
	const projects = [
		{
			id: 1,
			name: "A.N.D.I.E.",
			desc: "A Non-Destructive Image Editor (ANDIE) is a basic image editing software equipped with standard tools such as cropping, rotating, applying filters, and drawing shapes. This was another group project I undertook as part of a Software Development course. ANDIE was my first programming group project and served as an introduction to collaborative Git repositiories and resolving merge conflicts.",
			skills: ["Java", "GitLab"],
		},
		{
			id: 2,
			name: "Login Languish",
			desc: "Login Languish is an infuriating yet humorous game mocking the unforgiving nature of account signup processes. We worked on this project as a team of four students for a Software Engineering course during my last semester of study. It uses Ultralight-SDK to display a React frontend which is hooked up to a backend written in C++ largely consisting of input validation and unit testing.",
			skills: ["C++", "React,js", "GitHub"],
		},
		{
			id: 3,
			name: "Portfolio Website",
			desc: "Although fairly static, this portfolio site is one of my proudest web-dev projects to date. Each and every component was built from scratch just to prove to myself that I could create a visually appealing, functional and unique site without the shortcut of using component libraries. That being said, I think that being resourceful is a great strength of mine, and outside of this project I always utilise whichever tools I consider to be the best for the job at hand. Reach out if you would like to see the codebase.",
			skills: ["React.js"],
		},
	];

	return (
		<div className="section" id="project-section">
			<h1 className="section-header">Projects</h1>
			{projects
				.slice()
				.reverse()
				.map((project) => (
					<div className="project" key={project.id}>
						<h3 className="project-name">{project.name}</h3>
						<p className="project-desc">{project.desc}</p>
						<ul className="skill-tags">
							{project.skills.map((skill, index) => (
								<li key={index}>{skill}</li>
							))}
						</ul>
					</div>
				))}
		</div>
	);
}
