export default function Experience() {
	const experience = [
		{
			id: 1,
			date: "2020 - 2023",
			place: "University of Otago",
			name: "Computer Science Major (BSc)",
			desc: "Studying computer science at the University of Otago taught me a great deal about the fundamentals of programming. Beyond the basics, some of the important concepts I studied were data structures, algorithms, AI, version control, CI/CD, game design, database management, and much, much more. Get in touch if you're interested in seeing my transcript.",
			skills: ["Python", "Java", "C#", "JavaScript", "C", "C++", "GitLab"],
		},
		{
			id: 2,
			date: "FEB '23 - NOV '23",
			place: "University of Otago",
			name: "Programming Demonstrator",
			desc: "During my last year of study, I was offered to demonstrate coding in Java to a first-year computer science course where I would guide students and provide assistance should they need it. I found this role to be extremely beneficial in my ability to communicate programming concepts while also improving my own coding competency by reinforcing the basics.",
			skills: ["Java"],
		},
		{
			id: 3,
			date: "JAN '24 - APR '24",
			place: "Hero (f.k.a. Linc-Technologies)",
			name: "Full Stack Development Intern",
			desc: "My main objective during this internship was the research and development of implementing an AI assistant into Hero’s school administration platform. This project involved making API calls and performing CRUD operations from a backend written in Go, developing an appealing and responsive frontend with Ember.js, prompt engineering, and onsite consulting with customer schools. After finishing the AI assistant early, I developed an alternative to a paid-for widget which showcases Hero’s latest updates and blog posts to their customers, saving them $12K annually. I learned a great deal at Hero from working as part of a successful development team and following their agile production processes.",
			skills: ["Ember.js", "GoLang", "GitHub"],
		},
		{
			id: 4,
			date: "AUG '24 -",
			place: "Permaconn (Patriot Systems)",
			name: "Junior Full Stack Developer",
			desc: "So far while working for Patriot Systems (under Permaconn), I’ve already had the opportunity to work with multiple technologies and contribute to their range of alarm monitoring software projects despite being relatively new to the team. Generally, I'm developing new features for Patriot's widely used .NET desktop client, or I'm migrating its key features to the new web client, utilising React with TypeScript and GraphQL. Additionally, I played a role in the early development of the standalone Dispatch service, where I contributed by implementing API endpoints and unit tests. I am grateful that my time here so far has allowed me to navigate different codebases and experience tackling a variety of challenges with different tech stacks.",
			skills: [
				"C#",
				".NET",
				"React.js",
				"TypeScript",
				"GraphQL",
				"WPF",
				"GitLab",
			],
		},
	];

	return (
		<div className="section" id="experience-section">
			<h1 className="section-header">Relevant Experience</h1>
			<div className="timeline">
				{experience
					.slice()
					.reverse()
					.map((exp) => (
						<div className="timeline-event">
							<div className="timeline-circle"></div>
							<p className="timeline-date">{exp.date}</p>
							<div className="exp-content">
								<h3 className="exp-name">{exp.name}</h3>
								<p className="exp-place">{exp.place}</p>
								<p className="exp-desc">{exp.desc}</p>
								<ul className="skill-tags">
									{exp.skills.map((skill, index) => (
										<li key={index}>{skill}</li>
									))}
								</ul>
							</div>
						</div>
					))}
			</div>
		</div>
	);
}
