import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaperPlaneTilt } from "@phosphor-icons/react";

import "./Portfolio.css";

function Contact() {
	function handleSubmit(e) {
		e.preventDefault(); // necessary for the email to be sent

		// get form fields
		const nameInput = document.querySelector(".contact-form__name");
		const emailInput = document.querySelector(".contact-form__email");
		const messageInput = document.querySelector(".contact-form__message");
		// get form values from fields
		const name = nameInput.value.trim();
		const email = emailInput.value.trim();
		const message = messageInput.value.trim();
		// validate that no field is empty
		if (name === "" || email === "" || message === "") {
			toast.error("Please fill out all fields.");
			return;
		}

		toast.promise(
			emailjs
				.sendForm(
					"service_1h1b257",
					"template_k9rcvlw",
					e.target,
					"Av2Bcg3zlU5XgxkwD"
				)
				.then(
					(result) => {
						// clear form fields after successful submission
						nameInput.value = "";
						emailInput.value = "";
						messageInput.value = "";
					},
					(error) => {
						toast.error(error.text);
					}
				),
			{
				pending: "Sending email",
				success: {
					render() {
						return (
							<div>
								Thanks for reaching out. <br />
								I'll be in touch shortly.
							</div>
						);
					},
					autoClose: 10000,
				},
				error: "Something went wrong :(",
			}
		);
	}

	return (
		<div className="Contact">
			<h1 className="section-header">Contact Me</h1>
			<form onSubmit={handleSubmit} className="contact-form">
				<input
					type="text"
					autoComplete="name"
					name="from_name"
					placeholder="Name"
					className="contact-form__name"
				/>
				<input
					type="email"
					autoComplete="email"
					name="from_email"
					placeholder="Email address"
					className="contact-form__email"
				/>
				<textarea
					name="message"
					placeholder="Type message here..."
					className="contact-form__message"
				/>
				<button type="submit" className="contact-form__submit">
					<PaperPlaneTilt size={22} className="submit-icon" color="#F5F5F5" />
					<p>Shoot</p>
				</button>
			</form>
			<ToastContainer
				className="toast-container"
				position="bottom-right"
				autoClose={5000}
				hideProgressBar
				theme="dark"
			/>
		</div>
	);
}

export default Contact;
